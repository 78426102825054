import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "Layouts/default"
import Config from "Data"
import { SEO, FeaturedSection, EventsGrid, FooterCTA, SectionCallout } from "Components"
import { Gap } from "Styles"

const ThankYou = () => (
  <StaticQuery
    query={query}
    render={(data) => {
      let allEventsData = [].concat(data.events2020.edges, data.events2021.edges,
        data.events2022.edges,
        data.events2023.edges
      )
      let allEvents = allEventsData.sort((a, b) => (a.node.date > b.node.date ? 1 : -1))

      return (
        <Layout
          locale={"en-us"}
          region={"us"}
          simple={true}
          sticky={true}
          transparentHeader={false}
          transparentFooter={true}>
          <SEO
            pathname="/thank-you"
            title={`Thank You | ${Config.brandBusiness} by ${Config.contact.name}`}
            description={`We've received your request. Our team will review the request and get in touch soon.`}
          />
          <Helmet>
            <meta name="robots" content="noindex,nofollow" />
          </Helmet>
          <Gap />
          <Gap />
          <Gap />

          <FeaturedSection
            dark={false}
            title={`Thank You.`}
            subtitle={`We've received your request. Our team will review the request and get in touch soon.`}
            cta_url1_text={``}
            cta_url1={``}
            page={`Thank you page`}
          />
          {/* {console.debug(allEvents)} */}
          <SectionCallout small={true} dark={false} grid={true} page={`Thank you page`}>
            <EventsGrid
              button={true}
              countdown={false}
              events={allEvents}
              coming={true}
              show={6}
              big={true}
              filter={false}
              header={true}
              now={false}
              regions={""}
              title={`Upcoming events`}
              page={`Thank you page`}
            />
          </SectionCallout>

          <FooterCTA
            dark={false}
            title="Got questions?"
            subtitle={`Get in touch directly by sending us an email at ${Config.contact.email} or give us a call:`}
            cta_url1={`tel:${Config.contact.phone.us}`}
            cta_url1_text={`${Config.contact.phone.us}`}
            cta_url2={`tel:${Config.contact.phone.uk}`}
            cta_url2_text={`${Config.contact.phone.uk}`}
            page={`Thank you page`}
          />
        </Layout>
      )
    }}
  />
)

const query = graphql`
  query thankYouQuery {
    events2020: allGoogleSheetOverview2020(filter: { calendar: { eq: true },audience: {eq: "CFO"} }, sort: { order: DESC, fields: date }) {
      totalCount
      edges {
        node {
          id
          brand
          region
          country
          events
          eventTitle
          eventType
          eventTime
          audience
          industry
          panel
          stateCity
          date
          eventSite
          twitter
          image
          webinarLink
        }
      }
    }
    events2021: allGoogleSheetOverview2021(filter: { calendar: { eq: true },audience: {eq: "CFO"} }, sort: { order: DESC, fields: date }) {
      totalCount
      edges {
        node {
          id
          brand
          region
          country
          events
          eventTitle
          eventType
          eventTime
          audience
          industry
          panel
          stateCity
          date
          eventSite
          twitter
          image
          webinarLink
        }
      }
    }
    events2022: allGoogleSheetOverview2022(filter: { calendar: { eq: true },audience: {eq: "CFO"} }, sort: { order: DESC, fields: date }) {
      totalCount
      edges {
        node {
          id
          brand
          region
          country
          events
          eventTitle
          eventType
          eventTime
          audience
          industry
          panel
          stateCity
          date
          eventSite
          twitter
          image
          webinarLink
        }
      }
    }
    events2023: allGoogleSheetOverview2023(filter: { calendar: { eq: true },audience: {eq: "CFO"} }, sort: { order: DESC, fields: date }) {
      totalCount
      edges {
        node {
          id
          brand
          region
          country
          events
          eventTitle
          eventType
          eventTime
          audience
          industry
          panel
          stateCity
          date
          eventSite
          twitter
          image
          webinarLink
        }
      }
    }
  }
`

export default ThankYou
